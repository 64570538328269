import { useEffect, useRef } from "react"
import { Outlet, useNavigate, NavLink } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { PropagateLoader } from "react-spinners";
import { jwtDecode } from 'jwt-decode';
import axios from "axios";

import { Menubar } from 'primereact/menubar';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';

import { setUser } from "./store/featrues/auth/authSlice";

import VoxBorderButton from "./components/VoxBorderButton";

import logo from './assets/svg/logo.svg'
import UserPlus from "./assets/svg/UserPlus.svg"
import User from "./assets/svg/User.svg"
import Events from './assets/svg/Ticket.svg'
import Explore from './assets/svg/Newspaper.svg'
import Gear from './assets/svg/Gear.svg'
import SignOut from './assets/svg/SignOut.svg'
import Down from './assets/svg/down.svg'


export const verifyToken = (serviceToken, type = "Admin") => {
    if (!serviceToken) {
        return false;
    }
    try {
        const decoded = jwtDecode(serviceToken);

        /**
         * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
         */
        return decoded.exp > (Date.now() / 1000) - (60 * 60 * 24);
    } catch (error) {
        console.log(error)
        return false;
    }
};

export default function Layout() {

    const menuRight = useRef(null)
    const menubarRef = useRef(null);

    const navigate = useNavigate()
    const { authToken, user, loading } = useSelector((state) => state.auth)
    const { title, to } = useSelector((state) => state.data)
    const dispatch = useDispatch()

    useEffect(() => {

        const fetchUser = async (authToken) => {
            try {
                const response = await axios.post('auth/profile', {
                    headers: {
                        Authorization: `Bearer ${authToken}`
                    }
                })
                dispatch(setUser(response.data))
            } catch (error) {
                console.log(error)
                navigate("/login")
            }
        }

        if (!authToken || !verifyToken(authToken)) {
            navigate("/login")
            localStorage.removeItem('authToken')
        } else if (verifyToken(authToken)) {
            fetchUser(authToken)
        }
    }, [authToken, dispatch, navigate])



    const menuitemRenderer = (item) => (
        <NavLink className="h-8 px-2 flex items-center" to={item.to}>
            {item.icon}
            <span className="mx-2 text-white text-xs uppercase font-generalsans">{item.label}</span>
        </NavLink>
    );

    const menuitems = [
        {
            label: 'Requested Invites',
            icon: <img src={UserPlus} className="w-5 h-5" alt="" />,
            to: '/invites',
            template: menuitemRenderer
        },
        {
            label: 'Users',
            icon: <img src={User} className="w-5 h-5" alt="" />,
            to: '/users',
            template: menuitemRenderer
        },
        {
            label: 'Events',
            icon: <img src={Events} className="w-5 h-5" alt="" />,
            to: '/events',
            template: menuitemRenderer
        },
        {
            label: 'Explore',
            icon: <img src={Explore} className="w-5 h-5" alt="" />,
            to: '/explore',
            template: menuitemRenderer
        },
    ];

    const itemRenderer = (item) => (
        <NavLink className="px-2 flex items-center bg-[#070603] h-10 font-generalsans" to={item.to} onClick={item.click}>
            {item.icon}
            <span className="mx-2 text-white text-xs uppercase">{item.label}</span>
        </NavLink>
    );

    const items1 = [
        {
            label: 'My Account',
            to: '/account',
            icon: <img src={Gear} className="w-5 h-5" alt="" />,
            template: itemRenderer
        },
        {
            label: 'Logout',
            click: () => {
                localStorage.removeItem('authToken')
                navigate("/login")
            },
            icon: <img src={SignOut} className="w-5 h-5" alt="" />,
            template: itemRenderer
        }
    ]

    const start = <img alt="logo" src={logo} className="mr-2 w-8"></img>;
    const end = (
        <div className="flex align-items-center gap-2">
            <Menu
                model={items1}
                popup ref={menuRight}
                id="popup_menu_right"
                popupAlignment="right"
                className="bg-[#5B5549] rounded-xl py-0 overflow-hidden [&>ul]:gap-[1px] [&>ul]:flex [&>ul]:flex-col"
            />
            <Button
                label={user?.name}
                icon={<img src={Down} alt="" />}
                className="flex flex-row-reverse gap-2 text-white text-xs uppercase outline-none border-none shadow-none [&>span]:font-generalsans [&>span]:font-medium"
                onClick={(event) => menuRight.current.toggle(event)}
                aria-controls="popup_menu_right"
                aria-haspopup
            />
            <button className="p-menubar-button lg:!hidden" onClick={(e) => menubarRef.current.toggle(e)}>
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" className="p-icon" aria-hidden="true" ><path fillRule="evenodd" clipRule="evenodd" d="M13.3226 3.6129H0.677419C0.497757 3.6129 0.325452 3.54152 0.198411 3.41448C0.0713707 3.28744 0 3.11514 0 2.93548C0 2.75581 0.0713707 2.58351 0.198411 2.45647C0.325452 2.32943 0.497757 2.25806 0.677419 2.25806H13.3226C13.5022 2.25806 13.6745 2.32943 13.8016 2.45647C13.9286 2.58351 14 2.75581 14 2.93548C14 3.11514 13.9286 3.28744 13.8016 3.41448C13.6745 3.54152 13.5022 3.6129 13.3226 3.6129ZM13.3226 7.67741H0.677419C0.497757 7.67741 0.325452 7.60604 0.198411 7.479C0.0713707 7.35196 0 7.17965 0 6.99999C0 6.82033 0.0713707 6.64802 0.198411 6.52098C0.325452 6.39394 0.497757 6.32257 0.677419 6.32257H13.3226C13.5022 6.32257 13.6745 6.39394 13.8016 6.52098C13.9286 6.64802 14 6.82033 14 6.99999C14 7.17965 13.9286 7.35196 13.8016 7.479C13.6745 7.60604 13.5022 7.67741 13.3226 7.67741ZM0.677419 11.7419H13.3226C13.5022 11.7419 13.6745 11.6706 13.8016 11.5435C13.9286 11.4165 14 11.2442 14 11.0645C14 10.8848 13.9286 10.7125 13.8016 10.5855C13.6745 10.4585 13.5022 10.3871 13.3226 10.3871H0.677419C0.497757 10.3871 0.325452 10.4585 0.198411 10.5855C0.0713707 10.7125 0 10.8848 0 11.0645C0 11.2442 0.0713707 11.4165 0.198411 11.5435C0.325452 11.6706 0.497757 11.7419 0.677419 11.7419Z" fill="currentColor"></path></svg>            
            </button>
        </div>
    )



    return (
        <div className="w-full h-full min-h-[100vh] bg-[#070603] pb-20 relative">
            {
                loading &&
                <div className="w-full min-h-screen h-full flex justify-center items-center absolute bg-[#070603] z-10 bg-opacity-60">
                    <PropagateLoader color="#FFF" />
                </div>
            }
            <div className="h-[74px] w-full bg-[#18140c] flex justify-center items-center">
                <div className="max-w-screen-xl w-full header px-8 md:p-0">
                    <Menubar
                        model={menuitems}
                        start={start}
                        end={end}
                        className="bg-transparent border-none flex gap-8"
                        ref={menubarRef}
                    />
                </div>
            </div>
            <div className="w-full flex justify-center items-center">
                <div className="max-w-screen-xl w-full px-5 lg:px-[30px]">
                    <div className="w-full pt-14 pb-8 flex justify-between">
                        <div className="font-normal text-[25px] leading-[31px] text-white font-quichesans">{title}</div>
                        {
                            to &&
                            <VoxBorderButton label="Add New" onClick={() => navigate(to)} iconType={'plus'} />
                        }
                    </div>
                    <Outlet />
                </div>
            </div>
        </div>
    )
}