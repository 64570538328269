import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DateRange } from 'react-date-range';
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";


import VoxBorderButton from "../../components/VoxBorderButton";
import VoxButton from "../../components/VoxButton";
import VoxInput from "../../components/VoxInput";
import VoxTextarea from "../../components/VoxTextarea";
import VoxImagePicker from "../../components/VoxImagePicker";
import VoxSuccess from "../../components/VoxSuccess";
import VoxError from "../../components/VoxError";
import { setTo, setEvent, setTitle } from "../../store/featrues/data/dataSlice";
import { setLoading } from "../../store/featrues/auth/authSlice";


export default function AddNewEvent() {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const timeInputRef = useRef(null)
    const dateRangeRef = useRef(null)

    const { event } = useSelector(state => state.data)

    const [acts, setActs] = useState([])
    const [location, setLocation] = useState('')
    const [details, setDetails] = useState('')
    const [assignedActs, setAssignedActs] = useState([])
    const [itineraries, setItineraries] = useState([{ theme: '', itinerary: [] }])
    const [eventImage, setEventImage] = useState(null)
    const [itineraryImages, setItineraryImages] = useState([{ theme: '', itinerary: [], themeImage: '' }])
    const [selectionRange, setSelectionRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ])

    const [showDateRangePicker, setShowDateRangePicker] = useState(false)
    const [dateRangeText, setDateRangeText] = useState('')
    const [selectedTime, setSelectedTime] = useState('00:00')
    const [actSearch, setActSearch] = useState('')
    const [savedSuccess, setSavedSuccess] = useState(false)
    const [errors, setErrors] = useState(null)

    useEffect(() => {
        dispatch(setTo(""))
        dispatch(setTitle("Events"))

        const fetchActs = async () => {
            try {
                const { data } = await axios.get('acts')
                setActs(data)
            } catch (error) {

            }
        }

        if (event) {
            setItineraries([])
            setItineraryImages([])
            event.itineraries.forEach((item, index) => {
                setItineraryImages((pre) => ([...pre, { theme: '', itinerary: [...event.itineraryImages[index].itinerary] }]))
                setItineraries((pre) => ([...pre, { theme: item.theme, itinerary: [...item.itinerary] }]))
            })

            const selectionRangeDate = event.selectionRange.map(range => ({
                ...range,
                startDate: range.startDate ? new Date(range.startDate) : null,
                endDate: range.endDate ? new Date(range.endDate) : null
            }))

            setLocation(event.location)
            setDetails(event.details)
            setAssignedActs(event.assignedActs)
            setSelectionRange(selectionRangeDate)
            setEventImage(event.eventImage)
            setAssignedActs(event.assignedActs)
            setSelectedTime(event.selectedTime)

            dispatch(setEvent(null))
        }

        fetchActs()
    }, [])

    useEffect(() => {
        if (selectionRange[0].startDate && selectionRange[0].endDate) {
            setDateRangeText(formatDateRange(selectionRange[0].startDate, selectionRange[0].endDate))
        } else {
            setDateRangeText("Date")
        }
    }, [selectionRange])

    const handleDateChange = (item) => {
        const selection = item.selection;
        
        // Set time to 12:00:00 for both start and end date
        const adjustedStartDate = new Date(selection.startDate);
        adjustedStartDate.setHours(10, 0, 0, 0); // Sets time to 12:00:00
    
        const adjustedEndDate = new Date(selection.endDate);
        adjustedEndDate.setHours(10, 0, 0, 0); // Sets time to 12:00:00
    
        setSelectionRange([{
            startDate: adjustedStartDate,
            endDate: adjustedEndDate,
            key: selection.key
        }]);
    };

    const handleSaveEvent = async () => {
        const formData = new FormData();
        itineraryImages.forEach((fileRow, rowIndex) => {
            fileRow['itinerary'].forEach((file, colIndex) => {
                formData.append(`files[${rowIndex}][${colIndex}]`, file)
            })
            formData.append(`themeFiles[${rowIndex}]`, fileRow['themeImage'])
        })
        formData.append("file", eventImage)
        formData.append("date", JSON.stringify(selectionRange))
        formData.append("time", selectedTime)
        formData.append("location", location)
        formData.append("details", details)
        formData.append("acts", JSON.stringify(assignedActs))
        formData.append("itineraries", JSON.stringify(itineraries))

        dispatch(setLoading(true))
        try {
            await axios.postForm('events', formData)
            setSavedSuccess(true)
            dispatch(setLoading(false))
        } catch (error) {
            setErrors(error?.response?.data?.data)
            dispatch(setLoading(false))
        }
    }


    const handleClickOutside = (event) => {
        if (dateRangeRef.current && !dateRangeRef.current.contains(event.target)) {
            setShowDateRangePicker(false);
        }
    };

    useEffect(() => {
        if (showDateRangePicker) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showDateRangePicker]);

    const handleNewAct = () => {
        const serializedSelectionRange = selectionRange.map(range => ({
            ...range,
            startDate: range.startDate ? range.startDate.toISOString() : null,
            endDate: range.endDate ? range.endDate.toISOString() : null
        }));


        dispatch(setEvent({
            location,
            details,
            itineraries,
            itineraryImages,
            eventImage,
            selectionRange: serializedSelectionRange,
            selectedTime,
            assignedActs
        }))
        navigate('/events/add-new-act')
    }


    return (
        <div className="bg-[#18140c] flex flex-col gap-5 w-full rounded-lg pt-5 pb-10 items-center min-h-[645px]">
            <div className="w-full text-white px-5 justify-between flex items-center border-b border-b-[##5B5549] pb-5 font-quichesans font-normal text-xl">
                {`Add New Event`}

                <VoxBorderButton label={'Cancel'} onClick={() => navigate('/events')} />
            </div>

            {
                !savedSuccess &&
                <div className="max-w-[690px] w-full pt-10 gap-10 flex flex-col px-5">
                    {
                        errors && 
                        <VoxError label={Object.values(errors)[0][0]} />
                    }

                    <div className="w-full flex flex-col gap-5">
                        <div className="text-xl text-white font-quichesans font-normal">Event Details</div>

                        <VoxImagePicker
                            label={'add feature image'}
                            setFile={setEventImage}
                            file={eventImage}
                            error={errors && errors['file']}
                        />

                        <div className="relative grid grid-cols-1 lg:grid-cols-2 gap-5">
                            <button className="border border-[#C1B7A8] rounded-[5px] py-[15px] px-[20px] flex justify-between text-xs text-white" onClick={() => setShowDateRangePicker((pre) => !pre)}>
                                {
                                    dateRangeText
                                }
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.375 5.25L7 9.625L2.625 5.25" stroke="white" strokeWidth="1.5" strokeLinecap="square" />
                                </svg>
                            </button>

                            <input
                                type="time"
                                ref={timeInputRef}
                                className="border border-[#C1B7A8] rounded-[5px] py-[15px] px-[20px] flex justify-between text-xs text-white bg-transparent"
                                onFocus={() => timeInputRef.current?.showPicker()}
                                value={selectedTime}
                                onChange={(e) => setSelectedTime(e.target.value)}
                            />
                            <div ref={dateRangeRef}>
                                <DateRange
                                    editableDateInputs={true}
                                    onChange={item => handleDateChange(item)}
                                    moveRangeOnFirstSelection={false}
                                    ranges={selectionRange}
                                    className={`absolute top-16 ${showDateRangePicker ? 'block' : '!hidden'}`}
                                    showDateDisplay={false}
                                />
                            </div>
                        </div>

                        <VoxInput
                            placeholder={'LOCATION'}
                            classsName="shadow-none w-full"
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                            error={errors && errors['location']}
                        />
                        <VoxTextarea
                            placeholder={'EVENT DETAILS'}
                            classsName="w-full"
                            rows={5}
                            value={details}
                            onChange={(e) => setDetails(e.target.value)}
                            error={errors && errors['details']}
                        />
                    </div>

                    <div className="w-full flex flex-col gap-5">
                        <div className="text-xl text-white font-quichesans font-normal flex justify-between items-center">
                            Acts
                            <VoxBorderButton label={'Add New Act'} onClick={() => handleNewAct()} />
                        </div>
                        <div className="w-full h-[58px] border-[#C1B7A8] border rounded-md flex items-center px-5">
                            <input
                                className="bg-transparent w-full text-sm font-normal text-white outline-none"
                                placeholder="Assign Act"
                                value={actSearch}
                                onChange={(e) => setActSearch(e.target.value)}
                            />
                            <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.8905 18.7439L13.9499 13.8033C15.3346 12.2801 16.0687 10.2757 15.9952 8.2184C15.9217 6.16114 15.0465 4.21421 13.5566 2.79364C12.0668 1.37308 10.0804 0.591538 8.02199 0.616038C5.96356 0.640538 3.99635 1.46913 2.54073 2.92476C1.0851 4.38039 0.256504 6.3476 0.232004 8.40602C0.207504 10.4644 0.989042 12.4508 2.40961 13.9407C3.83018 15.4305 5.77711 16.3057 7.83437 16.3792C9.89163 16.4527 11.896 15.7187 13.4193 14.3339L18.3599 19.2746C18.4303 19.3449 18.5257 19.3845 18.6252 19.3845C18.7247 19.3845 18.8202 19.3449 18.8905 19.2746C18.9609 19.2042 19.0004 19.1088 19.0004 19.0093C19.0004 18.9097 18.9609 18.8143 18.8905 18.7439ZM1.00022 8.50926C1.00022 7.10007 1.4181 5.72252 2.201 4.55082C2.98391 3.37912 4.09668 2.46589 5.39861 1.92662C6.70053 1.38734 8.13313 1.24624 9.51524 1.52116C10.8974 1.79608 12.1669 2.47467 13.1634 3.47112C14.1598 4.46757 14.8384 5.73712 15.1133 7.11924C15.3882 8.50135 15.2471 9.93395 14.7079 11.2359C14.1686 12.5378 13.2554 13.6506 12.0837 14.4335C10.912 15.2164 9.53442 15.6343 8.12523 15.6343C6.23617 15.6323 4.42505 14.881 3.08928 13.5452C1.75352 12.2094 1.00221 10.3983 1.00022 8.50926Z" fill="#897151" />
                            </svg>
                        </div>

                        {
                            acts?.filter((a) => assignedActs.includes(a.id)).length > 0 &&
                            <div className="flex flex-wrap gap-5">
                                {
                                    acts?.filter((a) => assignedActs.includes(a.id)).map((act, index) =>
                                        <div className="h-[70px] bg-[#070603] bg-opacity-30 rounded-[100px] flex gap-[10px] p-[10px] items-center">
                                            <img src={`${process.env.REACT_APP_STORAGE_URL}${act.image}`} className="w-12 h-12 rounded-full" alt="" />
                                            <div className="text-sm font-normal text-white"> {act.name} </div>
                                            <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="cursor-pointer" onClick={() => setAssignedActs(pre => pre.filter(p => p !== act.id))}>
                                                <path d="M3.53535 4.46514L10.6064 11.5362" stroke="#897151" strokeLinecap="square" />
                                                <path d="M10.6064 4.46428L3.53535 11.5353" stroke="#897151" strokeLinecap="square" />
                                            </svg>
                                        </div>
                                    )
                                }
                            </div>
                        }

                        {
                            actSearch &&
                            acts?.filter((a) => !assignedActs.includes(a.id))?.filter((act) => act.name.toLowerCase().includes(actSearch.toLowerCase())).map((act, index) =>
                                <div className="bg-[#070603] bg-opacity-30 rounded-[5px] h-20 w-full flex justify-between items-center px-5" key={index}>
                                    <div className="flex gap-5 items-center">
                                        <img src={`${process.env.REACT_APP_STORAGE_URL}${act.image}`} className="w-12 h-12 rounded-full" alt="" />
                                        <div className="text-sm text-white font-normal">{act.name}</div>
                                    </div>
                                    <VoxBorderButton label={'Assign'} onClick={() => setAssignedActs((pre) => [...pre, act.id])} />
                                </div>
                            )
                        }
                        {
                            actSearch &&
                            acts?.filter((a) => !assignedActs.includes(a.id))?.filter((act) => act.name.toLowerCase().includes(actSearch.toLowerCase())).length === 0 &&
                            <div className="rounded-[5px] bg-[#070603] bg-opacity-30 h-[74px] w-full flex justify-between px-5 items-center">
                                <div className="text-sm text-white font-medium">No Results</div>
                                <VoxBorderButton label={'Add New Act'} onClick={() => handleNewAct()} />
                            </div>
                        }

                    </div>

                    <div className="w-full flex flex-col gap-5">
                        <div className="text-xl text-white font-quichesans font-normal">Itinerary</div>

                        {
                            itineraries?.map((iti, index) =>
                                <div className="flex flex-col gap-5 w-full" key={index}>
                                    <div className="text-[#897151] text-xs font-medium uppercase">
                                        {
                                            `day ${index + 1}`
                                        }
                                    </div>
                                    <div className="flex flex-row gap-5">
                                        <div className="flex-grow">
                                            <VoxInput
                                                placeholder={'THEME'}
                                                classsName="shadow-none w-full"
                                                value={iti.theme}
                                                onChange={(e) => setItineraries((pre) => {
                                                    let newIti = [...pre]
                                                    newIti[index] = { ...newIti[index], theme: e.target.value }
                                                    return newIti
                                                })}
                                            />
                                        </div>
                                        <div>
                                            <VoxImagePicker
                                                label={'add theme image'}
                                                setFile={(file) => setItineraryImages((pre) => {
                                                    let newIti = [...pre]
                                                    newIti[index]['themeImage'] = file
                                                    return newIti
                                                })}
                                                file={itineraryImages[index]['themeImage']}
                                            />
                                        </div>
                                    </div>
                                    {
                                        iti['itinerary']?.map((i, index1) =>
                                            <div className="w-full bg-[#070603] p-5 gap-5 bg-opacity-30 grid grid-cols-1 lg:grid-cols-2 rounded-[5px]" key={index1}>
                                                <div className="text-[#897151] text-xs font-medium uppercase lg:col-span-2">activity item</div>
                                                <VoxImagePicker
                                                    label={'add feature image'}
                                                    setFile={(file) => setItineraryImages((pre) => {
                                                        let newIti = [...pre]
                                                        newIti[index]['itinerary'][index1] = file
                                                        return newIti
                                                    })}
                                                    file={itineraryImages[index]['itinerary'][index1]}
                                                />
                                                <VoxInput
                                                    placeholder={'ACTIVITY NAME'}
                                                    classsName="w-full"
                                                    onChange={(e) => setItineraries((pre) => {
                                                        let newIti = [...pre]
                                                        newIti[index]['itinerary'][index1] = { ...newIti[index]['itinerary'][index1], name: e.target.value }
                                                        return newIti
                                                    })}
                                                    value={itineraries[index]['itinerary'][index1].name}
                                                />
                                                <select
                                                    className="border border-[#C1B7A8] rounded-[5px] py-[15px] px-[20px] flex justify-between text-xs text-white bg-transparent"
                                                    placeholder="DURATION"
                                                    onChange={(e) => setItineraries((pre) => {
                                                        let newIti = [...pre]
                                                        newIti[index]['itinerary'][index1] = { ...newIti[index]['itinerary'][index1], duration: e.target.value }
                                                        return newIti
                                                    })}
                                                    value={itineraries[index]['itinerary'][index1].duration}
                                                >
                                                    <option value={''}>DURATION</option>
                                                    <option>1 hour</option>
                                                    <option>2 hours</option>
                                                    <option>3 hours</option>
                                                    <option>4 hours</option>
                                                    <option>5 hours</option>
                                                    <option>6 hours</option>
                                                    <option>7 hours</option>
                                                    <option>8 hours</option>
                                                    <option>9 hours</option>
                                                    <option>10 hours</option>
                                                </select>
                                                <input
                                                    type="time"
                                                    className="border border-[#C1B7A8] rounded-[5px] py-[15px] px-[20px] flex justify-between text-xs text-white bg-transparent"
                                                    onFocus={(e) => e.target?.showPicker()}
                                                    onChange={(e) => setItineraries((pre) => {
                                                        let newIti = [...pre]
                                                        newIti[index]['itinerary'][index1] = { ...newIti[index]['itinerary'][index1], time: e.target.value }
                                                        return newIti
                                                    })}
                                                    value={itineraries[index]['itinerary'][index1].time}
                                                />
                                            </div>
                                        )
                                    }
                                    <div className="flex justify-between">
                                        <VoxBorderButton label={'Add Item'} iconType={'plus'} className="w-max" onClick={() => {
                                            setItineraries((pre) => {
                                                const newIti = [...pre]
                                                newIti[index]['itinerary'].push({
                                                    name: '',
                                                    duration: '',
                                                    time: ''
                                                })
                                                return newIti
                                            })
                                            setItineraryImages((pre) => {
                                                const newIti = [...pre]
                                                newIti[index]['itinerary'].push(null)
                                                return newIti
                                            })
                                        }} />

                                        <VoxBorderButton label={'Remove Item'} iconType={'minus'} type='danger' className="w-max text-sm" onClick={() => {
                                            setItineraries((pre) => {
                                                const newIti = [...pre]
                                                const itineraries = newIti[index]['itinerary'];
                                                if (itineraries.length > 0) {
                                                    itineraries.pop()
                                                }
                                                return newIti
                                            })
                                            setItineraryImages((pre) => {
                                                const newIti = [...pre];
                                                const itineraryImages = newIti[index]['itinerary'];
                                                if (itineraryImages.length > 0) {
                                                    itineraryImages.pop()
                                                }
                                                return newIti
                                            })
                                        }} />
                                    </div>
                                </div>
                            )
                        }

                        <div className="w-full flex items-center gap-[10px]">
                            <div className=" border-b border-b-[#5B5549] w-full"></div>
                            <VoxBorderButton
                                label={'Add Day'}
                                iconType={'plus'}
                                className="w-max text-nowrap"
                                onClick={() => {
                                    setItineraries((pre) => [...pre, { theme: '', itinerary: [] }])
                                    setItineraryImages((pre) => [...pre, { theme: '', itinerary: [], themeImage: null }])
                                }} />
                            <div className=" border-b border-b-[#5B5549] w-full"></div>
                        </div>
                        <div className="flex flex-col md:flex-row gap-5">
                            <div className="flex justify-between w-full">
                                <VoxButton label={'Add Event'} className="w-max" onClick={handleSaveEvent} />
                                <div className="flex items-center gap-5">
                                    <div className='text-[#C51102] text-sm hidden md:block'>Please Note: This action cannot be undone.</div>
                                    <VoxBorderButton label={'Delete Day'} type='danger' className='text-sm' onClick={() => {
                                        setItineraries((pre) => {
                                            const itineraries = [...pre]
                                            if( itineraries.length > 1 ) {
                                                itineraries.pop()
                                            }
                                            return itineraries
                                        })
                                        setItineraryImages((pre) => {
                                            const itineraryImages = [...pre]
                                            if( itineraryImages.length > 1 ) {
                                                itineraryImages.pop()
                                            }
                                            return itineraryImages
                                        })
                                    }} />
                                </div>
                            </div>
                            <div className='text-[#C51102] text-sm block md:hidden'>Please Note: This action cannot be undone.</div>
                        </div>
                    </div>
                </div>
            }
            {
                savedSuccess &&
                <VoxSuccess content={'New event successfully added.'} to={'/events'} btnLabel={'Back to events'} />
            }
        </div>
    )
}



export const formatDateRange = (startDate, endDate) => {
    // Helper function to pad single digit numbers with a leading zero
    const padToTwoDigits = (num) => num.toString().padStart(2, '0');

    // Format startDate
    const startYear = startDate.getFullYear().toString().slice(-2); // Last 2 digits of year
    const startMonth = padToTwoDigits(startDate.getMonth() + 1); // Month is zero-based
    const startDay = padToTwoDigits(startDate.getDate());

    // Format endDate
    const endYear = endDate.getFullYear().toString().slice(-2); // Last 2 digits of year
    const endMonth = padToTwoDigits(endDate.getMonth() + 1); // Month is zero-based
    const endDay = padToTwoDigits(endDate.getDate());

    // Concatenate to desired format
    return `${startDay}/${startMonth}/${startYear} - ${endDay}/${endMonth}/${endYear}`;
};