import { useNavigate } from "react-router-dom"
import VoxButton from "./VoxButton"


export default function VoxSuccess({ title = "Success!", content, btnLabel, to, onClick }){

    const navigate = useNavigate()

    return (
        <div className="max-w-[690px] w-full flex flex-col gap-[30px] pt-20 px-5">
            <div className="text-white text-[25px] leading-[31px] font-normal font-quichesans">{title}</div>
            <div className="text-white text-base font-normal font-generalsans">{content}</div>
            <VoxButton label={btnLabel} onClick={() => to ? navigate(to) : onClick()} className="max-w-[335px]" />
        </div>
    )
}