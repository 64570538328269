import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";

import VoxBorderButton from "../../components/VoxBorderButton";
import VoxImagePicker from "../../components/VoxImagePicker";
import VoxInput from "../../components/VoxInput";
import VoxTextarea from "../../components/VoxTextarea";
import VoxButton from "../../components/VoxButton";
import VoxSuccess from "../../components/VoxSuccess";

import { setLoading } from "../../store/featrues/auth/authSlice";


export default function AddNewAct(){

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [ name, setName ] = useState('')
    const [ bio, setBio ] = useState('')
    const [ image, setImage ] = useState(null)

    const [ savedSuccess, setSavedSuccess ] = useState(false)

    const handleSave = async () => {
        if( !image || !name || !bio  ) return;

        dispatch(setLoading(true))
        try {

            const formData = new FormData();
            formData.append('file', image);
            formData.append('name', name);
            formData.append('bio', bio);

            const response = await axios.postForm('acts', formData )
            setSavedSuccess(true)
            dispatch(setLoading(false))
        } catch (error) {
            console.log(error?.response?.data?.message)
            // setError(error?.response?.data?.message)
            dispatch(setLoading(false))
        }
    }

    return (
        <div className="bg-[#18140c] flex flex-col gap-5 w-full rounded-lg pt-5 pb-10 items-center min-h-[645px]">
            <div className="w-full text-white px-5 justify-between flex items-center border-b border-b-[##5B5549] pb-5 font-quichesans font-normal text-xl">
                {`Add New Act`}

                <VoxBorderButton label={'Cancel'} onClick={() => navigate('/events/add-new')} />
            </div>

            {
                !savedSuccess && 
                <div className="max-w-[690px] w-full pt-10 gap-5 flex flex-col px-5">
                    <VoxImagePicker 
                        label="add act image" 
                        setFile={setImage}
                        file={image}
                    />

                    <VoxInput 
                        placeholder={'ACT NAME'} 
                        classsName="w-full" 
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />

                    <VoxTextarea 
                        placeholder={'ACT BIO'} 
                        classsName="w-full" 
                        value={bio}
                        onChange={(e) => setBio(e.target.value)}
                    />

                    <VoxButton 
                        className="w-max" 
                        label={'Add Act'} 
                        onClick={handleSave}
                    />
                </div>
            }

            {
                savedSuccess && 
                <VoxSuccess 
                    content={'New act successfully added.'}
                    btnLabel={'Back to add new event'}
                    to={"/events/add-new"}
                />
            }

        </div>
    )
}