import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { setTitle, setTo } from "../store/featrues/data/dataSlice";
import { setLoading } from "../store/featrues/auth/authSlice";

import VoxBorderButton from "../components/VoxBorderButton";

export default function Invites() {

    const [ requestedInvites, setRequestedInvites] = useState([]);

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setTitle("Requested Invites"))
        dispatch(setTo(""))

        const fetchInvites = async () => {
            dispatch(setLoading(true))
            try {
                const { data } = await axios.get('users', {
                    params: {
                        role: "Guest", 
                        active: "Await"
                    }
                })
                setRequestedInvites(data)
                dispatch(setLoading(false))
            } catch (error) {
                console.log(error)
                dispatch(setLoading(false))
            }
        }

        fetchInvites();
    }, [])

    const stateUpdate = async (id, state) => {
        dispatch(setLoading(true))
        try {
            const { data } = await axios.put(`users/${id}`, {
                active: state
            })
            setRequestedInvites(prev => prev.filter(invite => invite.id !== id))
            dispatch(setLoading(false))
        } catch (error) {
            dispatch(setLoading(false))
            console.log(error)
        }
    }

    const buttonsTemplate = ( row ) => {
        return (
            <div className="flex gap-2 justify-end">
                <VoxBorderButton onClick={() => stateUpdate(row.id, "Accept")} label={`Accept`}/>
                <VoxBorderButton onClick={() => stateUpdate(row.id, "Decline")} label={`Decline`}/>
            </div>
        )
    }

    const dateFormat = (row) => {
        const date = new Date( row?.created_at )
        
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();

        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${day}/${month}/${year} ${hours}:${minutes}`;
    }

    return (
        <div className="bg-[#18140c] flex flex-col gap-5 w-full rounded-lg py-5">
            <div className="w-full text-white px-5 font-quichesans font-normal text-xl">
                {`Submissions`}
            </div>
            <DataTable value={ requestedInvites } tableStyle={{ minWidth: '50rem' }}>
                <Column header="Date" body={dateFormat}></Column>
                <Column field="name" header="Name"></Column>
                <Column field="email" header="Email"></Column>
                <Column field="phone" header="Phone"></Column>
                <Column header="" body={ buttonsTemplate }></Column>
            </DataTable>
        </div>
    );
}