


export default function VoxTextarea({placeholder, value, classsName, rows = 3, onChange, label= "", error = '' }) {
    return (
        <div className='flex flex-col gap-[6px]'>
            {
                label && 
                <div className='text-white text-xs font-medium'> {label} </div>
            }
            <textarea 
                className={`vox-textarea outline-none border border-[#C1B7A8] bg-transparent rounded py-[15px] px-5 text-sm text-white font-normal font-generalsans leading-5 ${ classsName }`} 
                placeholder={placeholder}
                rows={rows}
                onChange={onChange}
                value={value}
            >
            </textarea>
            {
                error && 
                <div className='text-[#C51102] text-sm'>{error}</div>
            }
        </div>
    )
}