import { useState, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { DateRange } from 'react-date-range'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import { Dropdown } from 'primereact/dropdown';

import { setLoading } from '../../store/featrues/auth/authSlice'
import { setTo, setTitle } from '../../store/featrues/data/dataSlice'
import { formatDateRange } from './addNew'

import VoxBorderButton from '../../components/VoxBorderButton'
import VoxButton from '../../components/VoxButton'
import VoxImagePicker from '../../components/VoxImagePicker'
import VoxInput from '../../components/VoxInput'
import VoxTextarea from '../../components/VoxTextarea'
import VoxSuccess from '../../components/VoxSuccess'


export default function EditEventDetail() {

    const navigate = useNavigate()
    const { id } = useParams()
    const dispatch = useDispatch()

    const dateRangeRef = useRef(null)


    const [event, setEvent] = useState(null)
    const [eventImage, setEventImage] = useState(null)
    const [selectionRange, setSelectionRange] = useState([
        {
            startDate: new Date(),
            endDate: null,
            key: 'selection'
        }
    ])
    const [location, setLocation] = useState('')
    const [details, setDetails] = useState('')
    const [showDateRangePicker, setShowDateRangePicker] = useState(false)
    const [dateRangeText, setDateRangeText] = useState('')
    const [selectedTime, setSelectedTime] = useState(new Date())
    const [savedSuccess, setSavedSuccess] = useState(false)
    const [featured, setFeatured] = useState({ name: 'No' });
    const [locked, setLocked] = useState({ name: 'No' });

    const featureds = [
        { name: 'Yes' },
        { name: 'No' },
    ];

    useEffect(() => {
        dispatch(setTo(""))
        dispatch(setTitle("Events"))
        dispatch(setLoading(true))

        const fetchEvent = async (id) => {
            try {
                const { data } = await axios.get(`events/${id}`);
                setEvent(data)
                setSelectionRange([
                    {
                        startDate: new Date(data?.date[0]?.startDate),
                        endDate: new Date(data?.date[0]?.endDate),
                        key: 'selection'
                    }
                ])
                setSelectedTime(data?.time)
                setLocation(data?.location)
                setDetails(data?.details)
                setFeatured({ name: data?.featured })
                setLocked({ name: data?.locked })
                dispatch(setLoading(false))
            } catch (error) {
                dispatch(setLoading(false))
            }
        }

        fetchEvent(id)
    }, [])

    useEffect(() => {
        if (selectionRange[0]?.startDate && selectionRange[0]?.endDate) {
            setDateRangeText(formatDateRange(selectionRange[0].startDate, selectionRange[0].endDate))
        } else {
            setDateRangeText("Date")
        }
    }, [selectionRange])


    const handleDateChange = (item) => {
        const selection = item.selection;
        
        // Set time to 12:00:00 for both start and end date
        const adjustedStartDate = new Date(selection.startDate);
        adjustedStartDate.setHours(10, 0, 0, 0); // Sets time to 12:00:00
    
        const adjustedEndDate = new Date(selection.endDate);
        adjustedEndDate.setHours(10, 0, 0, 0); // Sets time to 12:00:00
    
        setSelectionRange([{
            startDate: adjustedStartDate,
            endDate: adjustedEndDate,
            key: selection.key
        }]);
    };


    const handleSaveChanges = async () => {
        dispatch(setLoading(true))
        const formData = new FormData();
        if (eventImage) {
            formData.append("file", eventImage)
        }
        formData.append("date", JSON.stringify(selectionRange))
        formData.append("time", selectedTime)
        formData.append("location", location)
        formData.append("details", details)
        formData.append("featured", featured?.name)
        formData.append("locked", locked?.name)
        formData.append('_method', 'put');

        try {
            await axios.postForm(`events/${id}`, formData)
            setSavedSuccess(true)
            dispatch(setLoading(false))
        } catch (error) {
            console.log(error)
            dispatch(setLoading(false))
        }
    }

    const handleClickOutside = (event) => {
        if (dateRangeRef.current && !dateRangeRef.current.contains(event.target)) {
            setShowDateRangePicker(false);
        }
    };

    useEffect(() => {
        if (showDateRangePicker) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showDateRangePicker]);


    const itemTemplate = (option) => {
        return (
            <div onClick={() => setFeatured({name: option.name})} className="p-dropdown-custom-label text-xs">
                {option.name}
            </div>
        );
    };

    const lockedItemTemplate = (option) => {
        return (
            <div onClick={() => setLocked({name: option.name})} className="p-dropdown-custom-label text-xs">
                {option.name}
            </div>
        );
    };


    return (
        <div className="bg-[#18140c] flex flex-col gap-5 w-full rounded-lg pt-5 pb-10 items-center min-h-[645px]">
            <div className="w-full text-white px-5 justify-between flex items-center border-b border-b-[##5B5549] pb-5 font-normal text-xl font-quichesans">
                {`Edit Event Details`}

                <VoxBorderButton label={'Cancel'} onClick={() => navigate(`/events/${id}`)} />
            </div>
            {
                !savedSuccess &&
                <div className="max-w-[690px] w-full pt-10 gap-10 flex flex-col px-5">
                    <div className="w-full flex flex-col gap-5">
                        <VoxImagePicker
                            label={'Edit FEATURED IMAGE'}
                            setFile={setEventImage}
                            url={event?.image}
                            file={eventImage}
                        />

                        <div className="relative grid grid-cols-1 lg:grid-cols-6 gap-5">
                            <div className='flex flex-col gap-[6px] lg:col-span-2'>
                                <div className='text-white text-xs font-medium'>DATE</div>
                                <button className="border border-[#C1B7A8] rounded-[5px] py-[15px] px-[20px] flex justify-between text-xs text-white" onClick={() => setShowDateRangePicker((pre) => !pre)}>
                                    {
                                        dateRangeText
                                    }
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.375 5.25L7 9.625L2.625 5.25" stroke="white" strokeWidth="1.5" strokeLinecap="square" />
                                    </svg>
                                </button>
                            </div>
                            <div className='grid grid-cols-1 lg:grid-cols-3 lg:col-span-4 gap-2'>
                                <div className='flex flex-col gap-[6px]'>
                                    <div className='text-white text-xs font-medium'>TIME</div>
                                    <input
                                        type="time"
                                        className="border border-[#C1B7A8] rounded-[5px] py-[15px] px-[20px] flex justify-between text-xs text-white bg-transparent"
                                        onFocus={(e) => e.target?.showPicker()}
                                        value={selectedTime}
                                        onChange={(e) => setSelectedTime(e.target.value)}
                                    />
                                </div>
                                <div className='flex flex-col gap-[6px]'>
                                    <div className='text-white text-xs font-medium'>FEATURED</div>
                                    <Dropdown 
                                        value={featured} 
                                        itemTemplate={itemTemplate}
                                        options={featureds} 
                                        optionLabel="name" 
                                        placeholder="Featured" 
                                        className="bg-transparent border rounded-[5px] border-[#C1B7A8] !shadow-none [&>.p-dropdown-label]:bg-transparent [&>.p-dropdown-label]:text-white [&>.p-dropdown-label]:text-xs [&>.p-dropdown-label]:font-normal [&>.p-dropdown-label]:pl-5 [&>.p-dropdown-label]:py-[16px]" 
                                    />
                                </div>
                                <div className='flex flex-col gap-[6px]'>
                                    <div className='text-white text-xs font-medium'>LOCKED</div>
                                    <Dropdown 
                                        value={locked} 
                                        itemTemplate={lockedItemTemplate}
                                        options={featureds} 
                                        optionLabel="name" 
                                        placeholder="Locked" 
                                        className="bg-transparent border rounded-[5px] border-[#C1B7A8] !shadow-none [&>.p-dropdown-label]:bg-transparent [&>.p-dropdown-label]:text-white [&>.p-dropdown-label]:text-xs [&>.p-dropdown-label]:font-normal [&>.p-dropdown-label]:pl-5 [&>.p-dropdown-label]:py-[16px]" 
                                    />
                                </div>
                            </div>
                            <div ref={dateRangeRef}>
                                <DateRange
                                    editableDateInputs={true}
                                    onChange={item => handleDateChange(item)}
                                    moveRangeOnFirstSelection={false}
                                    ranges={selectionRange}
                                    className={`absolute top-20 ${showDateRangePicker ? 'block' : '!hidden'}`}
                                    showDateDisplay={false}
                                />
                            </div>
                        </div>

                        <VoxInput placeholder={'LOCATION'} classsName="shadow-none w-full" value={location} onChange={(e) => setLocation(e.target.value)} label={"LOCATION"} />
                        <VoxTextarea placeholder={'EVENT DETAILS'} classsName="w-full" rows={15} value={details} onChange={(e) => setDetails(e.target.value)} label='EVENT DETAILS' />
                    </div>

                    <VoxButton label={'Save Changes'} onClick={handleSaveChanges} className='w-max text-sm' />
                </div>
            }
            {
                savedSuccess &&
                <VoxSuccess content={'Event details successfully updated.'} to={`/events/${id}`} btnLabel={'Back to event'} />
            }
        </div>
    )
}