import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";

import VoxBorderButton from "../../components/VoxBorderButton";
import VoxImagePicker from "../../components/VoxImagePicker";
import VoxInput from "../../components/VoxInput";
import VoxTextarea from "../../components/VoxTextarea";
import VoxButton from "../../components/VoxButton";
import VoxSuccess from "../../components/VoxSuccess";
import VoxDeleteModal from "../../components/VoxDeleteModal";

import { setLoading } from "../../store/featrues/auth/authSlice";


export default function EditAct(){

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { id, actId } = useParams()

    const [ event, setEvent ] = useState('')
    const [ act, setAct ] = useState( null )
    const [ name, setName ] = useState('')
    const [ bio, setBio ] = useState('')
    const [ image, setImage ] = useState(null)

    const [ savedSuccess, setSavedSuccess ] = useState(false)
    const [ modalOpen, setModalOpen ] = useState(false)

    useEffect(() => {
        dispatch(setLoading(true))
        const fetchData = async () => {
            try {
                const { data } = await axios.get(`acts/${actId}`)

                setName(data.name)
                setBio(data.bio)
                setAct(data)


                const response = await axios.get(`events/${id}`)
                setEvent(response.data)
                dispatch(setLoading(false))

            } catch (error) {
                console.log(error)
                dispatch(setLoading(false))
            }
        }

        fetchData()
    }, [])

    const handleSave = async () => {
        if( !name || !bio  ) return;

        dispatch(setLoading(true))
        try {

            const formData = new FormData();
            if( image ) {
                formData.append('file', image);
            }
            formData.append('name', name);
            formData.append('bio', bio);
            formData.append('_method', 'put');

            const { data } = await axios.postForm(`acts/${actId}`, formData )
            setSavedSuccess(true)
            dispatch(setLoading(false))
        } catch (error) {
            console.log(error?.response?.data?.message)
            dispatch(setLoading(false))
        }
    }

    const handleUnassignAct = async () => {
        setModalOpen(false)
        dispatch(setLoading(true))
        const formData = new FormData();
        formData.append("acts", JSON.stringify(event?.acts?.filter( id => id != actId )))
        formData.append('_method', 'put');

        try {
            const { data } = await axios.postForm(`events/${id}`, formData)
            dispatch(setLoading(false))
            navigate(`/events/${id}`)
        } catch (error) {
            dispatch(setLoading(false))
        }
    }

    return (
        <div className="bg-[#18140c] flex flex-col gap-5 w-full rounded-lg pt-5 pb-10 items-center min-h-[645px]">
            <div className="w-full text-white px-5 justify-between flex items-center border-b border-b-[##5B5549] pb-5 font-quichesans font-normal text-xl">
                {`Edit Act`}

                <VoxBorderButton label={'Cancel'} onClick={() => navigate(`/events/${id}`)} />
            </div>

            {
                !savedSuccess && 
                <div className="max-w-[690px] w-full pt-10 gap-5 flex flex-col px-5">
                    <VoxImagePicker 
                        label="Edit PROFILE PICTURE" 
                        setFile={ setImage }
                        file={image}
                        url={act?.image}
                    />

                    <VoxInput 
                        placeholder={'ACT NAME'} 
                        classsName="w-full" 
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />

                    <VoxTextarea 
                        placeholder={'ACT BIO'} 
                        classsName="w-full" 
                        value={bio}
                        onChange={(e) => setBio(e.target.value)}
                    />

                    <VoxButton 
                        className="w-max mt-5" 
                        label={'Edit Act'} 
                        onClick={handleSave}
                    />

                    <div className='flex items-center gap-5 flex-wrap mt-5'>
                        <VoxBorderButton label={'Unassign Act'} className='!border-[#C51102] !text-[#C51102] text-sm' onClick={() => setModalOpen(true)}  />
                        <div className='text-[#C51102] text-sm'>Please Note: This action cannot be undone.</div>
                    </div>
                </div>
            }

            {
                savedSuccess && 
                <VoxSuccess 
                    content={'Act successfully updated.'}
                    btnLabel={'Back to event'}
                    to={`/events/${id}`}
                />
            }

            <VoxDeleteModal open={modalOpen} setOpen={setModalOpen}  onConfirm={handleUnassignAct} title={'Unassign Act?'} content={'Are you sure? This cannot be undone. '} btnText={'Unassign Act'} />
        </div>
    )
}